
body {
    background: 'white';
}

input {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    height: 28px !important;
}

input:focus {
    border-color: black !important;
}

input[type="text"]:disabled {
    background: white !important;
}

select {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

select:focus {
    border-color: black !important;
}

label {
    font-size: 14px;
    margin-bottom: 0 !important;
}

.container-fluit {
    height: 100% !important;
}

img {
    width: 120px;
    height: 45px; /*35*/
    margin: 8px;
}

.logo {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-group {
    margin-bottom: 1.4rem !important;
}

.text-danger{
    font-size: 11px !important;
}

.dx-datagrid-nowrap, .dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content {
    white-space: normal !important;
}

.contenedor-productos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.contenedor-productos .producto {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 14px 0 0;
}

.contenedor-productos .producto label{
    padding: 0 6px 0 0;
    width: 35px;
}

#D2 {
    background: #616A6B;
}

#G84 {
    background: #E74C3C;
}

#G90, #GR {
    background: #28B463;
}

#G95, #GP {
    background: #3498DB;
}

#G97, #G98 {
    background: #58D68D;
}

#GLP, #GNV {
    background: #F5B041;
}

.fa-times-circle-o:before {
    font-size: 80px !important;
}

.modal-body {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
}

.login-input {
    width: 80% !important;
    margin: auto;
    text-align: center;
    border-radius: 25px !important;
    padding: 20px !important;
    background-color: rgba(255, 255, 255, 0.8) !important;
}

.login-submit{
    border-radius: 29px !important;
    padding: 10px !important;
    width: 50%;
}

.heading-section{
    color: white;
    font-family: 'Tajawal', sans-serif ;

}

.bloque{
    margin-top: 75px;  
}

.t-letter, .dx-datagrid-content, .Toastify__toast-body{
    /* font-family: 'Short Stack', cursive !important; */
    font-family: 'Capriola', sans-serif !important;
}

.Toastify__toast--error{
text-align: center;
}

.input-ejemplo{
    color: azure;
    margin: auto !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    margin-bottom: 10px !important;
    border-radius: 20px;
    padding: 10px 20px;
    box-shadow: 0px 10px 34px -15px rgba(255, 255, 255, 0.24) !important;

}

.div-go-logo{
    background-color: rgba(255, 255, 255, 0.5) !important;
    margin-top: -112px;
    height: 100px;
}

.go-logo{
    width: auto; 
    height: 80px;
}

@media (max-width: 576px) {
    .div-go-logo {
        height: 65px; }
    .go-logo{
        height: 50px;
    }
}
.no-readonly-style{
    background-color: white !important;
}
